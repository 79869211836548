import { select, put, takeEvery } from "redux-saga/effects";
import {
  SagaRegistry,
  requestHandler,
  setAlertAndLoading,
  getDateInFormat,
  sortByMapping,
  sortByDate,
  encyptDataObject,
  getMomentDate,
  commonAction,
} from "../common";
import { axios } from "../../utils/axios";
import { userActions } from "./slice";
import { isAdmin, isArchitect, isOrganizer, getCurrentUser } from "./helpers";
import Cookies from "universal-cookie";

const OTP_EXPAIRE_DURATION = process.env.REACT_APP_OTP_EXPAIRE_DURATION;
const OTP_EXPAIRE_DURATION_DEFAULT = 30;
const KEY_NM_API = "NM-API-Key";
const AUDIT_NM_API = "AUDIT-API-Key";
const MULTIFACTOR_COOKIE = "neu_multifactor";

const validateTokenAPI = async (token) => {
  const header = { [KEY_NM_API]: token };
  const res = await axios.post(
    "/tasks/api_user_detail",
    { task: { api_key: token } },
    header
  );
  const user = res.errors ? "Error" : res;
  if (user !== "Error") {
    localStorage.setItem(KEY_NM_API, token);
    const mod = await axios.get("/consoles/subscribed_grc_modules", header);
    user.modules = mod.modules;
  }
  return user;
};
const getNewNMIKEYObject = (longSessionToken) => {
  let sessionObj;
  if (longSessionToken && longSessionToken.trim().length > 0) {
    longSessionToken = longSessionToken.trim();
    sessionObj = window.atob(longSessionToken);
    sessionObj = JSON.parse(sessionObj);
  }
  return sessionObj;
};
function* checkForMultiFactorAuth({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const body = { user_email: payload.email };
    const res = yield axios.post("/consoles/is_multi_factor_enabled", body);
    let status, error;
    if (res.success) {
      status = res.enabled !== null ? "ENABLED" : "DISABLED";
    } else {
      status = "USER_NOT_FOUND";
      error = { data: { message: "User Not Found" } };
    }
    yield put(userActions.setMultiFactorAuthStatus(status));
    yield setAlertAndLoading(false);
    if (error) {
      throw error;
    }
  });
}
function* handlePostLogin(payload, user) {
  const token = user.api_key;
  const header = { [KEY_NM_API]: token };
  const data = { data: JSON.stringify(payload) };
  let encryptCreds = yield axios.post("/consoles/encrypt", data, header);
  if (encryptCreds) {
    encryptCreds[KEY_NM_API] = token;
    encryptCreds = window.btoa(JSON.stringify(encryptCreds));
  }
  yield put(userActions.validateToken({ token: encryptCreds }));
}
function* login({ payload }) {
  const cookies = new Cookies();
  try {
    yield setAlertAndLoading(true);
    const { otp, email, password } = payload;
    let body = { api: true, ent_usr: { email, password } };
    if (otp) {
      body.otp = otp;
      body.otp_required = true;
    }
    let res = yield axios.post("/login", body);
    if (res.current_ent_usr) {
      if (otp) {
        let duration = OTP_EXPAIRE_DURATION
          ? Number(OTP_EXPAIRE_DURATION)
          : OTP_EXPAIRE_DURATION_DEFAULT;
        duration = Number.isInteger(duration)
          ? duration
          : OTP_EXPAIRE_DURATION_DEFAULT;
        const expires = getMomentDate()
          .startOf("day")
          .add(duration, "days")
          .format();
        cookies.set(
          `${MULTIFACTOR_COOKIE}_${email}`,
          window.btoa(JSON.stringify({ email, expires })),
          { path: "/", secure: true }
        );
        yield handlePostLogin(payload, res.current_ent_usr);
      } else {
        if (res.current_ent_usr.is_disabled) {
          yield put(
            userActions.logInSuccess({
              status: { success: false, userDisabled: true },
            })
          );
        } else {
          body = { user_email: email };
          const multiFactorRes = yield axios.post(
            "/consoles/is_multi_factor_enabled",
            body
          );
          if (multiFactorRes.success && multiFactorRes.enabled) {
            let factorCookie = cookies.get(`${MULTIFACTOR_COOKIE}_${email}`);
            if (factorCookie) {
              factorCookie = JSON.parse(window.atob(factorCookie));
              let expired = getMomentDate(factorCookie.expires).isBefore(
                getMomentDate()
              );
              factorCookie =
                factorCookie.email !== email ||
                (factorCookie.email === email && expired)
                  ? false
                  : true;
            }
            if (!factorCookie) {
              yield put(
                userActions.logInSuccess({
                  status: { success: false, enableMultiFactor: true },
                })
              );
              yield setAlertAndLoading(false);
              return;
            }
          }
          yield handlePostLogin(payload, res.current_ent_usr);
        }
      }
    }
    yield setAlertAndLoading(false);
  } catch (error) {
    yield setAlertAndLoading(false);
    let status = { success: false };
    console.log("error", error);
    if (payload.otp) {
      status.invalidOTP = true;
    } else if (error && error.data.error === "USER_INACTIVE") {
      status.userDisabled = true;
    } else {
      status.invalidCredentials = true;
    }
    yield put(userActions.logInSuccess({ status }));
  }
}

function* validateToken({ payload }) {
  try {
    let NMKey,
      sessionObj,
      user = "Error",
      token = payload && payload.token;
    if (!token) {
      token = localStorage.getItem(AUDIT_NM_API);
    }
    if (token) {
      sessionObj = getNewNMIKEYObject(token);
      NMKey = sessionObj[KEY_NM_API];
      if (NMKey) {
        user = yield validateTokenAPI(NMKey);
        if (user !== "Error") {
          localStorage.setItem(AUDIT_NM_API, token);
          user.session_token = token;
        }
      }
    }
    yield put(
      userActions.logInSuccess({
        current_ent_usr: user,
        status: { success: user !== "Error" },
      })
    );
    yield put(commonAction.setAlert(null));
    yield setAlertAndLoading(false, null, false);
  } catch (error) {
    console.log("error", error);
    if (error.status === 401) {
      // let message = {
      //   autoClose: false,
      //   title: "LogIn is required",
      //   subtitle: "Your session is successfully logged out."
      // }
      // yield setAlertAndLoading(false, message);
      yield put(
        userActions.logInSuccess({
          status: { success: false },
          currentUser: "Error",
        })
      );
      localStorage.removeItem(KEY_NM_API);
      localStorage.removeItem(AUDIT_NM_API);
    } else {
      yield put(userActions.logInError());
      yield setAlertAndLoading(false);
    }
  }
}

function* inviteUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const body = { user: { email: payload.email } };
    let res;
    if (payload.modules) {
      body.user.modules = payload.modules;
    }
    if (payload.orgId) {
      body.ext_org_id = payload.orgId;
      res = yield axios.post("/consoles/external_org_invite_user", body);
    } else {
      res = yield axios.post("/consoles/invite_user", body);
    }
    yield setAlertAndLoading(false);
    yield put(userActions.inviteUserSuccess(res));
  });
}
function* inviteExternalUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    let res = {
      isOrgExist: false,
      isMappingExist: false,
      org: null,
      user: null,
      isUserMapped: false,
    };
    const { email, currentUserOrg, orgId } = payload;
    let body = { user: { email: email } };
    const { org, user } = yield axios.post(
      "/consoles/check_if_org_usr_exists",
      body
    );
    res.org = org;
    res.user = user;
    if (org) {
      res.isOrgExist = true;
      body = { vendor_id: orgId || currentUserOrg, customer_id: org.id };
      const { vendor, customer } = yield axios.post(
        "/consoles/mapping_exists",
        body
      );
      if (vendor && customer) {
        res.isMappingExist = true;
        if (user) {
          res.user = user;
          const { mapped_org, mapping } = yield axios.post(
            "/consoles/user_mapping_exists",
            { ent_usr_id: user.id, mapped_org_id: orgId || currentUserOrg }
          );
          if (mapped_org && mapping) {
            res.isUserMapped = mapping.activated;
          }
        }
        if (
          !Boolean(res.user) ||
          (Boolean(res.user) && !res.user.is_disabled && !res.isUserMapped)
        ) {
          if (payload.orgId) {
            res = yield axios.post("/consoles/external_org_invite_user", {
              user: { email: email },
              ext_org_id: orgId,
            });
          } else {
            yield axios.post("/consoles/invite_user", {
              user: { email: email },
            });
          }
        }
      }
    }
    console.log("res", res);
    yield put(userActions.inviteExternalUserSuccess(res));
    yield setAlertAndLoading(false);
  });
}

function* fetchResetTokenUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const body = { reset_password_token: payload.token };
    const res = yield axios.post("/consoles/user_by_reset_pwd", body);
    yield setAlertAndLoading(false);
    yield put(userActions.getResetTokenUserSuccess(res.user || "Invalid"));
  });
}
function* resetNewUserPassword({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { header, email, password } = payload;
    const body = {
      email: email,
      password: password,
      password_confirmation: password,
    };
    const res = yield axios.post("/consoles/reset_pwd", body, header);
    localStorage.setItem(KEY_NM_API, res.user.api_key);
    yield setAlertAndLoading(false);
    yield put(userActions.resetNewUserPasswordSuccess(res.user));
  });
}
function* fetchActiveUsers({ payload }) {
  yield requestHandler(function* () {
    const { orgId } = payload;
    let res = yield axios.get("/consoles/active_user_list?org_id=" + orgId);
    res = res.errors ? [] : res;
    res = res.map((_) => ({ ..._, name: `${_.firstname} ${_.lastname}` }));
    res.sort(sortByDate.bind(null, "created_at"));
    yield put(
      userActions.fetchActiveUsersSuccess({ users: res, orgId: orgId })
    );
  });
}
function* fetchInActiveUsers({ payload }) {
  yield requestHandler(function* () {
    const { orgId } = payload;
    let res = yield axios.get("/consoles/inactive_user_list?org_id=" + orgId);
    res = res.errors ? [] : res;
    res = res.map((_) => ({ ..._, name: `${_.firstname} ${_.lastname}` }));
    res.sort(sortByDate.bind(null, "updated_at"));
    yield put(
      userActions.fetchInactiveUsersSuccess({ users: res, orgId: orgId })
    );
  });
}
function* logout() {
  yield requestHandler(function* () {
    try {
      yield axios.post("/consoles/logout_api_user", {});
    } catch (error) {
      console.error("Logout Failed", error);
    }
    localStorage.removeItem(KEY_NM_API);
    localStorage.removeItem(AUDIT_NM_API);
    yield put(userActions.logoutSuccess());
    yield setAlertAndLoading(false);
  });
}

function* toggleUserActiveState({ payload }) {
  yield requestHandler(function* () {
    const { password, userId } = payload;
    let message;
    yield setAlertAndLoading(null, null, true);
    if (password) {
      const { current_user } = yield axios.post("/consoles/valid_password", {
        current_password: password,
      });
      if (!current_user) {
        message = {
          title: `Invalid Credentails`,
        };
        yield setAlertAndLoading(null, message, false);
        return;
      }
    }
    let res = yield axios.post("/consoles/activate_deactivate_user", {
      userId: userId,
    });
    message = {
      title: `User ${
        res.user.is_disabled ? "Deactivated" : "Activated"
      } Successfully`,
      subtitle: "The changes made to the user have been saved successfully!",
    };
    yield setAlertAndLoading(null, message, false);
    yield put(userActions.toggleActivateSuccess(true));
  });
}
function* fetchUserMappings({ payload }) {
  yield requestHandler(function* () {
    let userMap, orgId;
    let currentUser = yield select(getCurrentUser);
    if (payload && payload.orgId) {
      orgId = payload.orgId;
      userMap = yield axios.get(
        "/consoles/ext_org_accepcted_pending_user_list?ext_org_id=" + orgId
      );
    } else {
      orgId = currentUser.ent_org_id;
      userMap = yield axios.get("/consoles/accepcted_pending_user_list");
    }
    const { accepted_pending_user_list } = userMap;

    const canAction =
      isAdmin(currentUser) ||
      isArchitect(currentUser) ||
      isOrganizer(currentUser);
    let res = [];
    Object.values(accepted_pending_user_list).forEach((_) => {
      const {
        requester,
        customer,
        vendor,
        mapped_user,
        org_mapping,
        show_activate_deactivate_button,
      } = _;
      if (vendor && customer && org_mapping) {
        const org = orgId === vendor.id ? customer : vendor;
        let data = {
          org_mapping: org_mapping,
          vendorId: vendor.id,
          customerId: customer.id,
          userId: mapped_user.id,
          brandname: org.brandname,
          requester: requester
            ? `${requester.firstname} ${requester.lastname}`
            : "",
          requesterEmail: requester ? requester.email : "",
          requested: `${mapped_user.firstname} ${mapped_user.lastname || ""}`,
          datetime: getDateInFormat(
            show_activate_deactivate_button
              ? org_mapping.updated_at
              : org_mapping.created_at
          ),
        };
        if (show_activate_deactivate_button) {
          data.actions = canAction
            ? [
                { label: "Accept", action: "accept" },
                { label: "Reject", action: "reject" },
              ]
            : "Pending";
        } else {
          data.actions = org_mapping.status
            ? org_mapping.status
            : org_mapping.activated
            ? "Accepted"
            : "Pending";
        }
        res.push(data);
      }
    });
    res.sort(sortByMapping);
    yield put(userActions.fetchUserMappingsSuccess(res));
  });
}
function* acceptOrRejectUserMapping({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(null, null, true);
    let res = yield axios.post("/consoles/activate_deactivate", payload);
    let message = {
      title: `External User Mapping`,
      subtitle:
        "The changes made to the User mapping have been saved successfully!",
    };
    yield setAlertAndLoading(null, message, false);
    yield put(userActions.fetchUserMappings());
  });
}
function* createUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(null, null, true);
    let res = yield axios.post("/consoles/create_org_user", payload);
    let message = {
      title: `Create User`,
      subtitle: "The changes made to the User have been saved successfully!",
    };
    yield setAlertAndLoading(null, message, false);
    yield put(userActions.createUserSuccess(true));
  });
}
function* updateUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(null, null, true);
    let res = yield axios.post("/consoles/update_org_user", payload);
    let message = {
      title: `Update User`,
      subtitle: "The changes made to the User have been saved successfully!",
    };
    yield setAlertAndLoading(null, message, false);
    yield put(userActions.createUserSuccess(true));
    yield put(userActions.fetchUserSuccess(res.user));
  });
}
function* fetchCountryCodes({ payload }) {
  yield requestHandler(function* () {
    let header = (payload && payload.header) || {};
    let res = yield axios.get("/consoles/country_code_list", header);
    res = res.country_codes || {};
    res = Object.keys(res).map((key) => ({ label: res[key], id: key }));
    res.sort((a, b) => a.label.localeCompare(b.label));
    yield put(userActions.fetchCountryCodesSuccess(res));
  });
}
function* fetchRoles({ payload }) {
  yield requestHandler(function* () {
    let header = (payload && payload.header) || {};
    let res = yield axios.get("/centrals/module_role_list", header);
    res = (res.module_role_list && res.module_role_list.Console) || {};
    res = Object.keys(res).map((key) => {
      let val = res[key];
      return { label: val[1], id: val[0], idStr: key };
    });
    yield put(userActions.fetchRolesSuccess(res));
  });
}
function* fetchUser({ payload }) {
  yield requestHandler(function* () {
    let res = yield axios.get(
      "/consoles/get_user_detail_by_id?user_id=" + payload.userId
    );
    res = res.errors ? "NotFound" : res;
    yield put(userActions.fetchUserSuccess(res));
  });
}
function* sendPasswordLink({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(null, null, true);
    const body = { email: payload.email };
    let res = yield axios.post("/consoles/console_reset_pwd_link", body);
    let message = {
      title: `Password Reset link sent!`,
      subtitle:
        `Password Reset link is sent to the email that\n you provided.\nYou can close this window now!`.replace(
          /\n/g,
          "<br>"
        ),
    };
    yield put(userActions.sendPasswordLinkSuccess(true));
    yield setAlertAndLoading(null, message, false);
  });
}
function* changePassword({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true, null);
    let res = yield axios.post("/consoles/forgot_pwd", payload);
    localStorage.removeItem(KEY_NM_API);
    yield put(userActions.sendPasswordLinkSuccess(true));
    yield setAlertAndLoading(false, null);
  });
}
function* sendAuthOTP({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true, null);
    yield axios.post("/consoles/send_otp", { email: payload.email });
    yield put(userActions.setOTPStatus(true));
    yield setAlertAndLoading(false, null);
  });
}
function* bulkUploadUsers({ payload }) {
  yield requestHandler(function* () {
    const orgId = payload.get("org_id");
    yield setAlertAndLoading(true, null);
    yield axios.post("/consoles/bulk_user_upload", payload, {
      ...axios.getFormDataContentType(),
    });
    yield put(userActions.fetchActiveUsers({ orgId }));
    yield put(userActions.fetchInActiveUsers({ orgId }));
    let message = {
      subtitle: "File uploaded successfully!",
    };
    yield setAlertAndLoading(false, message);
  });
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("user/login", login);
  yield takeEvery("user/sendAuthOTP", sendAuthOTP);
  yield takeEvery("user/checkForMultiFactorAuth", checkForMultiFactorAuth);
  yield takeEvery("user/logout", logout);
  yield takeEvery("user/validateToken", validateToken);
  yield takeEvery("user/inviteUser", inviteUser);
  yield takeEvery("user/fetchResetTokenUser", fetchResetTokenUser);
  yield takeEvery("user/resetNewUserPassword", resetNewUserPassword);
  yield takeEvery("user/fetchActiveUsers", fetchActiveUsers);
  yield takeEvery("user/fetchInActiveUsers", fetchInActiveUsers);
  yield takeEvery("user/toggleActivate", toggleUserActiveState);
  yield takeEvery("user/inviteExternalUser", inviteExternalUser);
  yield takeEvery("user/fetchUserMappings", fetchUserMappings);
  yield takeEvery("user/acceptOrRejectUserMapping", acceptOrRejectUserMapping);
  yield takeEvery("user/fetchCountryCodes", fetchCountryCodes);
  yield takeEvery("user/fetchRoles", fetchRoles);
  yield takeEvery("user/createUser", createUser);
  yield takeEvery("user/updateUser", updateUser);
  yield takeEvery("user/fetchUser", fetchUser);
  yield takeEvery("user/sendPasswordLink", sendPasswordLink);
  yield takeEvery("user/changePassword", changePassword);
  yield takeEvery("user/bulkUploadUsers", bulkUploadUsers);
});
